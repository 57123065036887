import React from 'react'
import { connect } from 'react-redux'

import { AppState } from '../../../types'
import Layout from '../../common/templates/Layout'

import { Hero } from '../../common/molecules'
import ContentfulRenderer from '../../common/templates/ContentfulRenderer'

import { actions as layoutActions } from '../../common/templates/Layout/actions'
import { actions } from './actions'
import { pageDataSelector } from './selectors'
import { Props } from './types'
import { StyledHeroCarousel } from './styles'
import { deviceSizes } from '../../../styles/theme/breakpoints'

export const CustomSlug = (props: Props) => {
  const {
    pageData: { items },
  } = props
  const {
    slug,
    metaData,
    hero,
    addContent,
    trustBar,
    contentThemeTag,
    campaignNameFromPage,
    isTrustPilotRatingBarVisible,
    enableCustomHeaderBackground,
  } = items[0]

  const isUsingTransparentHero = !hero

  const isCarousel = hero && hero._type === 'heroCarousel'

  const contentTheme = contentThemeTag ? contentThemeTag[0] : ''

  const carouselConfig = {
    dots: true,
    slidesToShow: 1,
    adaptiveHeight: true,
    infinite: true,
    autoplay: true,
    fade: true,
    autoplaySpeed: 8000,
    arrows: true,
    responsive: [
      {
        breakpoint: deviceSizes.desktop + 1,
        settings: {
          arrows: false,
        },
      },
    ],
  }

  return (
    <Layout
      template="a"
      headerIsDark={true}
      isUsingTransparentHero={isUsingTransparentHero}
      enableCustomHeaderBackground={enableCustomHeaderBackground}
      slug={slug}
      metaData={metaData}
    >
      {!isCarousel && hero && (
        <Hero
          data={{ ...hero, heroType: 'textOnly' }}
          trustBar={trustBar}
          isTrustPilotRatingBarVisible={isTrustPilotRatingBarVisible}
        />
      )}
      {isCarousel && hero?.heros.length > 0 && (
        <StyledHeroCarousel className={'hero-carousel'} {...carouselConfig}>
          {getHeroCarouselItems(
            trustBar,
            isTrustPilotRatingBarVisible,
            hero.heros
          )}
        </StyledHeroCarousel>
      )}
      {/* passing campaignNameFromPage to Contentful Renderer explicitly as not part of contentEntry */}
      {addContent?.map((contentEntry) => (
        <ContentfulRenderer
          key={contentEntry._id}
          contentEntry={contentEntry}
          contentTheme={contentTheme}
          campaignNameFromPage={campaignNameFromPage}
        />
      ))}
    </Layout>
  )
}

const getHeroCarouselItems = (
  trustBar,
  isTrustPilotRatingBarVisible,
  heros = []
) =>
  heros.map((heroData, index) => (
    <Hero
      data-bdd-selector={'hero-carousel'}
      key={heroData._id || `hero-item-${index}`}
      data={{ ...heroData, heroType: 'textOnly' }}
      trustBar={trustBar}
      isTrustPilotRatingBarVisible={isTrustPilotRatingBarVisible}
    />
  ))

CustomSlug.getInitialProps = async ({ query, store, req }: any) => {
  const { customSlug, global } = store.getState()
  const { slug, parentSlug } = query
  const preview = req ? req.headers.host.indexOf('preview') !== -1 : null

  if (!customSlug.pageData) {
    let cmsSlug = slug
    const cmsParentSlug = parentSlug === undefined ? '/' : parentSlug

    if (!slug) {
      cmsSlug = `${cmsParentSlug}-index`
    }

    store.dispatch(actions.pageDataGet(cmsParentSlug, cmsSlug))

    await new Promise<void>((resolve) => {
      const unsubscribe = store.subscribe(() => {
        if (!customSlug.loading) {
          unsubscribe()
          resolve()
        }
      })
    })
  }

  if (!global.selectedArticles || global.selectedArticle?.filteredData) {
    if (preview !== null)
      store.dispatch(layoutActions.selectedArticlesGet(42, 0, preview))
  }
}

export const mapStateToProps = (state: AppState) => {
  return {
    pageData: pageDataSelector(state),
  }
}

export default connect(mapStateToProps)(CustomSlug)
