import styled from 'styled-components'

import { Carousel } from '../../common/molecules'

export const StyledHeroCarousel = styled(Carousel)`
  &.slick-slider {
    padding-top: 0 !important;
  }

  & .slick-list {
    padding: 0;
  }

  & .slick-list {
    margin: 0 -0.8rem;

    @media ${(props) => props.theme.breakpoints.desktop} {
      margin: 0 -1.2rem;
    }
  }

  & .slick-slide {
    box-sizing: border-box;
    padding: 0 0.8rem;

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding: 0 1.2rem;
    }

    & > div > div:first-child {
      padding-top: 10rem;

      @media ${(props) => props.theme.breakpoints.tablet} {
        padding-top: 14rem;
      }

      @media ${(props) => props.theme.breakpoints.desktop} {
        padding-top: 16.5rem;
      }
    }
  }

  & .slick-prev {
    margin-right: -5rem;
    z-index: 1;
    left: 1rem;
    @media ${(props) => props.theme.breakpoints.desktopLarge} {
      left: 2.5rem;
    }
    @media ${(props) => props.theme.breakpoints.desktopXXL} {
      left: 25rem;
    }
  }

  & .slick-next {
    margin-left: -5rem;
    z-index: 1;
    right: 1rem;

    @media ${(props) => props.theme.breakpoints.desktopLarge} {
      right: 2.5rem;
    }
    @media ${(props) => props.theme.breakpoints.desktopXXL} {
      right: 25rem;
    }
  }

  & .slick-dots {
    bottom: 2.5rem;
  }

  & .slick-current {
    z-index: 1;
  }
`
