import React from 'react'
import { Column, Container, Row } from '../../../atoms'

const withContainer =
  (Component: React.FC) =>
  ({ ...componentProps }) =>
    (
      <Container paddingSize="half">
        <Row>
          <Column>
            <Component {...componentProps} />
          </Column>
        </Row>
      </Container>
    )

export default withContainer
