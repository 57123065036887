import React from 'react'

import { RichText, TrustBar } from '../../atoms'
import {
  Accordion,
  CalloutBanner,
  CallToAction,
  ContentBlock,
  FeatureBlock,
  IconCardBlock,
  ThreeColumnsIcons,
  TrustpilotCarouselBlock,
  TwoColumnContentBlock,
  YoutubeVideo,
} from '../../molecules'
import {
  CallbackBanner,
  LocationsBlock,
  SelectedArticlesWithData,
  TabbedContentBlock,
} from '../../organisms'
import Table from '../../../containers/Table'

import { Props } from './types'
import withContainer from './hoc/withContainer'
import withDarkPalette from './hoc/withDarkPalette'
import withMappedProps from './hoc/withMappedProps'
import FormWrapper from '../../molecules/FormWrapper'
import PriceCalculatorBlock from '../../organisms/PriceCalculatorBlock'

export const ContentfulRenderer = ({
  contentEntry,
  contentTheme,
  campaignNameFromPage,
}: Props) => {
  contentEntry.contentTheme = contentTheme
  const ContentTypeMap = {
    accordionBlock: withContainer(withMappedProps(Accordion)),
    articleCardCarousel: SelectedArticlesWithData,
    callBackBanner: withMappedProps(CallbackBanner),
    callOutBanner: withMappedProps(CalloutBanner),
    callToAction: withContainer(CallToAction),
    columnBlockIcon: withMappedProps(ThreeColumnsIcons),
    form: withContainer(FormWrapper),
    featureBlockAnimation: withContainer(withMappedProps(ContentBlock)),
    featureBlock: withMappedProps(FeatureBlock),
    locationsBlock: withMappedProps(LocationsBlock),
    multipleIconColumnBlock: withMappedProps(IconCardBlock),
    pricingRateDetails: withMappedProps(PriceCalculatorBlock),
    richText: withContainer(
      withMappedProps(RichText, {
        propMap: [
          { propName: 'html', cfFieldName: 'richText' },
          { propName: 'useTicksForBullets', cfFieldName: 'useTicksForBullets' },
        ],
      })
    ),
    tabBlock: withDarkPalette(
      withMappedProps(TabbedContentBlock, {
        propMap: [
          { propName: 'data' },
          { propName: 'isNested', propValue: true },
        ],
      })
    ),
    table: withContainer(withMappedProps(Table)),
    trustBar: TrustBar,
    trustpilotCarouselContentBlock: withMappedProps(TrustpilotCarouselBlock),
    twoColumnContentBlock: withContainer(
      withMappedProps(TwoColumnContentBlock)
    ),
    video: withContainer(YoutubeVideo),
    videoBlock: withMappedProps(FeatureBlock),
  }

  const { _id: id, _type: contentType } = contentEntry
  const Component = ContentTypeMap[contentType]

  if (!Component) {
    console.warn(`${contentType} can not be handled`)
    return null
  }
  const componentProps: any = {
    ...contentEntry,
  }

  // campaignNameFromPage overwrites what comes in from form level
  // reformat as string better for testing snapshot
  if (componentProps._type === 'form') {
    if (componentProps.campaignName)
      componentProps.campaignName = componentProps.campaignName.name
    if (campaignNameFromPage)
      componentProps.campaignName = campaignNameFromPage.name
  }
  return <Component key={`${contentType}-${id}`} {...componentProps} />
}

export default ContentfulRenderer
