import React from 'react'

import { WithMappedPropsProps } from '../types'

const withMappedProps =
  (Component: React.FC, props: WithMappedPropsProps = {}) =>
  ({ ...cfFields }) => {
    const {
      propMap = [{ propName: 'data', cfFieldName: null, propValue: null }],
    } = props
    const getPropValue = (cfFieldName, propValue) => {
      if (propValue) return propValue
      if (cfFieldName) return cfFields[cfFieldName]
      return cfFields
    }

    const newProps = propMap.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.propName]: getPropValue(cur.cfFieldName, cur.propValue),
      }),
      {}
    )

    return <Component {...newProps} />
  }

export default withMappedProps
