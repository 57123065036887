import { styled } from '../../../../styles/theme'
import { colorTheme } from '../../../../styles/utils'
import { RichText } from '../../atoms'

import { ThemedElement } from './types'

export const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.background.rocketboyGradient};
  color: ${(props) => props.theme.colors.text.white};
`

const borderRadius = '3.2rem'

export const Headline = styled(RichText)`
  white-space: pre-line;

  h2 {
    font-size: ${(props) => props.theme.fontSizes.mobile.headings.h2};

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: ${(props) => props.theme.fontSizes.tablet.headings.h2};
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.headings.h3};
    }
  }

  b {
    color: ${(props) =>
      colorTheme(
        props.colorTheme,
        'priceBlockCalculator.textAccentThemeColor'
      )};
  }
`

export const Description = styled(RichText)`
  margin: 2rem 0 2.8rem;

  & p {
    margin-top: 0;
    line-height: ${(props) => props.theme.lineHeights.large};
    color: ${(props) => props.theme.colors.text.white};
  }
`

export const Caption = styled(RichText)`
  margin-top: 0.8rem;
  &,
  p {
    font-size: ${(props) => props.theme.fontSizes.mobile.body.primary};
  }
  line-height: ${(props) => props.theme.lineHeights.compact};
  color: ${(props) => props.theme.colors.text.white};

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 1.6rem;
    &,
    p {
      font-size: ${(props) => props.theme.fontSizes.mobile.body.primary};
    }
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 1.2rem;
    &,
    p {
      font-size: ${(props) => props.theme.fontSizes.mobile.body.primary};
    }
  }
`

export const CallOrBeCalled = styled.div<ThemedElement>`
  margin: 0 0 4rem;
  padding: 2.5rem;
  text-align: center;
  border-bottom-left-radius: ${borderRadius};
  border-bottom-right-radius: ${borderRadius};
  background: ${(props) =>
    colorTheme(
      props.colorTheme,
      'priceBlockCalculator.callOrBeCalledBackgroundThemeColor'
    )};
`

export const PhoneNumber = styled.div`
  display: inline-block;
  margin-right: 1.5rem;
  font-weight: ${(props) => props.theme.fontWeights.bold};

  p {
    &::after {
      content: 'or';
      margin-left: 1.5rem;
      font-weight: normal;
      font-style: italic;
    }

    a {
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
`
