import React from 'react'

import { DarkWrapper } from '../styles'

const withDarkPalette =
  (Component: React.FC) =>
  ({ ...componentProps }) =>
    (
      <DarkWrapper>
        <Component {...componentProps} />
      </DarkWrapper>
    )

export default withDarkPalette
