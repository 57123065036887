import React, { FunctionComponent } from 'react'

import { GetThemeContext, ThemeContext } from '../../../../context'
import { Row, Column, Container } from '../../atoms'
import { CallToAction } from '../../molecules'
import { PriceCalculator } from '../../organisms'

import {
  Wrapper,
  Caption,
  Description,
  Headline,
  CallOrBeCalled,
  PhoneNumber,
} from './styles'
import { Props } from './types'

const PriceCalculatorBlock: FunctionComponent<Props> = (props: Props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    data: {
      allPricingDetails,
      allPricingDetails: { pricingColumnCaption },
      headerContent,
      phoneNumber,
      callToAction,
    },
  } = props

  const filteredHtml =
    phoneNumber && phoneNumber.replace(/<[^\/>][^>]{1,20}><\/[^>]+>/, '')

  return (
    <Wrapper data-bdd-selector="price-calculator-block">
      <Container paddingSize="double">
        <Row>
          <Column tablet={[6, 1]} desktop={[5, 0]}>
            {headerContent.headline && (
              <Headline
                html={headerContent.headline}
                colorTheme={colorTheme}
                data-bdd-selector="price-calculator-block-headline"
              />
            )}
            {headerContent.description && (
              <Description
                html={headerContent.description}
                data-bdd-selector="price-calculator-block-description"
                useTicksForBullets
              />
            )}
          </Column>
          <Column desktop={7}>
            <PriceCalculator data={allPricingDetails} />
            {phoneNumber && callToAction && (
              <CallOrBeCalled colorTheme={colorTheme}>
                <PhoneNumber
                  dangerouslySetInnerHTML={{ __html: filteredHtml }}
                />
                <CallToAction {...callToAction} />
              </CallOrBeCalled>
            )}
            <Caption
              html={pricingColumnCaption}
              data-bdd-selector="price-calculator-block-caption"
            />
          </Column>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default PriceCalculatorBlock
